<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Update CPF</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Year" v-model="object.year"></v-text-field>
						</v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-select dense clearable label="Group*" :menu-props="{top: false, offsetY: true}" :items="groups" :rules="rules.group" :error-messages="errors.group" v-model="object.group"></v-select>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Base Wages*" :rules="rules.base_wages" :error-messages="errors.base_wages" v-model="object.base_wages"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Ordinary Wage Max Amount*" :rules="rules.ordinary_wages_max" :error-messages="errors.ordinary_wages_max" v-model="object.ordinary_wages_max"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-select dense clearable label="No of Year of PR*" :menu-props="{top: false, offsetY: true}" :items="prYears" :rules="rules.pr_year" :error-messages="errors.pr_year" v-model="object.pr_year"></v-select>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-select dense clearable label="FG Type*" :menu-props="{top: false, offsetY: true}" :items="fgTypes" :error-messages="errors.fg_type" v-model="object.fg_type"></v-select>
						</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Employee CPF Percentage*" :rules="rules.employee_percentage" :error-messages="errors.employee_percentage" v-model="object.employee_percentage"></v-text-field>
						</v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Employee CPF Weight*" :rules="rules.employee_weight" :error-messages="errors.employee_weight" v-model="object.employee_weight"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Employee CPF Max Amount*" :rules="rules.employee_max" :error-messages="errors.employee_max" v-model="object.employee_max"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Total CPF Percentage*" :rules="rules.total_percentage" :error-messages="errors.total_percentage" v-model="object.total_percentage"></v-text-field>
						</v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Total CPF Percentage 2*" :rules="rules.total_percentage2" :error-messages="errors.total_percentage2" v-model="object.total_percentage2"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Total CPF Weight*" :rules="rules.total_weight" :error-messages="errors.total_weight" v-model="object.total_weight"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense clearable label="Total CPF Max Amount*" :rules="rules.total_max" :error-messages="errors.total_max" v-model="object.total_max"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'CPFUpdateDialog',
    props: {
        groups: {
            type: Array,
            required: false,
            default: () => []
        },
        prYears: {
            type: Array,
            required: false,
            default: () => []
        },
        fgTypes: {
            type: Array,
            required: false,
            default: () => []
        }
    },
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                year: '',
                group: '',
                id: ''
			},
			rules: {
				group: [
                    (value) => !!value || 'Group is required',
                ],
                pr_year: [
                    (value) => !!value || 'No of year of PR is required',
                ],
                base_wages: [
                    (value) => {
                        if(!value){
                            return 'Base wages amount is required'
                        }

                        if(isNaN(value)){
                            return 'Base wages amount is numeric value'
                        }

                        return true
                    }
                ],
                ordinary_wages_max: [
                    (value) => {
                        if(!value){
                            return 'Ordinary wages max amount is required'
                        }

                        if(isNaN(value)){
                            return 'Ordinary wages max amount is numeric value'
                        }

                        return true
                    }
                ],
                employee_percentage: [
                    (value) => {
                        if(!value){
                            return 'Employee CPF percentage is required'
                        }

                        if(isNaN(value)){
                            return 'Employee CPF percentage is numeric value'
                        }

                        return true
                    }
                ],
                employee_weight: [
                    (value) => {
                        if(!value){
                            return 'Employee CPF weight is required'
                        }

                        if(isNaN(value)){
                            return 'Employee CPF weight is numeric value'
                        }

                        return true
                    }
                ],
                employee_max: [
                    (value) => {
                        if(!value){
                            return 'Employee CPF max amount is required'
                        }

                        if(isNaN(value)){
                            return 'Employee CPF max amount is numeric value'
                        }

                        return true
                    }
                ],
                total_percentage: [
                    (value) => {
                        if(!value){
                            return 'Total CPF percentage is required'
                        }

                        if(isNaN(value)){
                            return 'Total CPF percentage is numeric value'
                        }

                        return true
                    }
                ],
                total_percentage2: [
                    (value) => {
                        if(!value){
                            return 'Total CPF percentage2 is required'
                        }

                        if(isNaN(value)){
                            return 'Total CPF percentage2 is numeric value'
                        }

                        return true
                    }
                ],
                total_weight: [
                    (value) => {
                        if(!value){
                            return 'Total CPF weight is required'
                        }

                        if(isNaN(value)){
                            return 'Total CPF weight is numeric value'
                        }

                        return true
                    }
                ],
                total_max: [
                    (value) => {
                        if(!value){
                            return 'Total CPF max amount is required'
                        }

                        if(isNaN(value)){
                            return 'Total CPF max amount is numeric value'
                        }

                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.reset()
			this.form.resetValidation()
            this.object = { id: '', year: '', group: '', base_wages: '', ordinary_wages_max: '', fg_type: '', pr_year: '',
                            employee_percentage: '', employee_weight: '', employee_max: '', total_percentage: '',
                            total_percentage2: '', total_weight: '', total_max: ''}
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object, ['id'])

                this.$store.dispatch('epanel/setting/updateCPF', { id: this.object.id, object: object }).then((response) => {
                    var object = { object: response.data.object, message: response.message }
                    this.$emit('updated', object)
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		}
	}
}

</script>