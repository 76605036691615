<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-select dense label="Year" :menu-props="{top: false, offsetY: true}" :items="years" v-model="filters.year"></v-select>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table hide-default-footer class="elevation-1" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.group.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.base_wages"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.pr_year.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.fg_type.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.employee_percentage"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_percentage"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
								<v-menu bottom offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item @click="openUpdateDialog(index, item)">
											<v-list-item-title>Edit CPF</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<update-dialog ref="updateDialog" :groups="groups" :fg-types="fgTypes" :prYears="prYears" @updated="updateItem"></update-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { makeYearList } from '@/utils/dates'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import CPFUpdateDialog from './CPFUpdateDialog.vue'

export default {
	name: 'CPFList',
	components: {
        messageNotifier: MessageNotifier,
		updateDialog: CPFUpdateDialog
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				year: new Date().getFullYear().toString()
			},
			groups: [],
			prYears: [],
			fgTypes: [],
			items: [],
			headers: []
		}
	},
	computed: {
        years() {
            return makeYearList(2021)
        },
		updateDialog() {
			return this.$refs.updateDialog
		},
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/setting/getCPFs', this.filters).then((response) => {
				this.groups = response.data.groups
				this.prYears = response.data.pr_years
				this.fgTypes = response.data.fg_types
				this.items = response.data.items
				this.headers = response.data.headers
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.get()
		},
		reset: function() {
			this.filters = { year: ''}
		},
		openUpdateDialog: function(index, item) {
            this.selectedIndex = index
            var object = copyObject(item, ['group', 'fg_type', 'pr_year'])
            object.group = item.group.value
            object.fg_type = item.fg_type.value
            object.pr_year = item.pr_year.value
			this.updateDialog.updateObject(object)
			this.updateDialog.open()
		},
		updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
		},
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
	}
}

</script>